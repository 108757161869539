export default [
  //   "admin",
  // "manager",
  // "super-manager",
  // "hr",
  // "process-manager",
  // "accountant",
  // "super-accountant",
  // "markter"
  {
    title: 'Dashboards',
    route: 'dashboard',
    icon: 'fa-solid fa-house',
    role: [
      'admin',
      'manager',
      'super-manager',
      'hr',
      'process-manager',
      'accountant',
      'super-accountant',
      'markter',
    ],
  },
  {
    title: 'USER SITE',
    route: 'user-site',
    icon: 'fa-solid fa-user',
    action: 'browes',
    role: ['admin', 'manager', 'super-manager'],
  },
  {
    title: 'USER PANEL',
    route: 'user-panel',
    icon: 'fa-regular fa-user',
    action: 'browes',
    role: ['admin', 'manager', 'super-manager'],
  },
  // {
  //   title: 'Cities',
  //   route: 'cities',
  //   icon: 'fa-solid fa-map-location-dot',
  //   action: 'browes',
  //   role: ['admin'],
  // },
  {
    title: 'Offer',
    route: 'offer',
    icon: 'fa-solid fa-money-bill',
    action: 'browes',
    role: ['admin', 'manager', 'super-manager', 'markter'],
  },
  {
    title: 'Engineers Courses',
    route: 'engineers-courses',
    icon: 'fa-solid fa-graduation-cap',
    action: 'browes',
    role: ['admin', 'manager', 'super-manager', 'hr'],
  },
  {
    title: 'Eng. Assign Setup',
    route: 'engineers',
    icon: 'fa-solid fa-user-gear',
    action: 'browes',
    role: ['admin', 'manager', 'super-manager', 'process-manager'],
  },
  {
    title: 'Requests',
    icon: 'fa-solid fa-code-pull-request',
    route: 'requests',
    role: [
      'admin',
      'manager',
      'super-manager',
      'process-manager',
      'accountant',
      'super-accountant',
      'markter',
    ],
  },
  {
    title: 'Requests Over Due',
    icon: 'fa-solid fa-exclamation',
    route: 'requests-over-due',
    role: [
      'admin',
      'manager',
      'super-manager',
      'process-manager',
      'accountant',
      'super-accountant',
      'markter',
    ],
  },

  {
    title: 'Departments',
    route: 'departments',
    icon: 'fa-solid fa-industry',
    action: 'browes',
    role: ['admin', 'manager', 'super-manager'],
  },
  {
    title: 'certificate',
    route: 'main-certificate',
    icon: 'fa-certificate',
    action: 'browes',
    role: ['admin', 'manager', 'super-manager', 'process-manager'],
  },
  {
    title: 'office',
    route: 'office',
    icon: 'fa-solid fa-building',
    action: 'browes',
    role: [
      'admin',
      'manager',
      'super-manager',
      'accountant',
      'super-accountant',
    ],
  },
  {
    title: 'Delegator',
    route: 'delegator',
    icon: 'fa-solid fa-user-tag',
    action: 'browes',
    role: [
      'admin',
      'manager',
      'super-manager',
      'accountant',
      'super-accountant',
      'markter',
    ],
  },
  {
    title: 'Audit Set Money',
    route: 'set-money',
    icon: 'fa-solid fa-money-check',
    action: 'browes',
    role: [
      'admin',
      'manager',
      'super-manager',
      'accountant',
      'super-accountant',
    ],
  },
  {
    title: 'Audit Assigned',
    route: 'audit-assigned',
    icon: 'fa-regular fa-building',
    action: 'browes',
    role: ['admin', 'manager', 'super-manager', 'process-manager'],
  },

  {
    title: 'All Service',
    route: 'all-service',
    icon: 'fa-brands fa-servicestack',
    action: 'browes',
    role: ['admin', 'manager', 'super-manager', 'process-manager'],
  },
  {
    title: 'Service Need Approval',
    route: 'service-need-approval',
    icon: 'fa-solid fa-person-circle-check',
    action: 'browes',
    role: ['admin', 'manager', 'super-manager', 'process-manager'],
  },
  {
    title: 'Service Need Set Money',
    route: 'service-need-set-money',
    icon: 'fa-solid fa-money-check',
    action: 'browes',
    role: [
      'admin',
      'manager',
      'super-manager',
      'accountant',
      'super-accountant',
    ],
  },
]
